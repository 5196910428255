<template>
  <div>
    <!-- start of Court & Institution modal -->
    <b-modal id="court-modal" centered hide-footer hide-header size="xl">
      <!-- start of Chosen Court Section -->
      <div class="row">
        <div class="col-auto">
          <b>Chosen Court(s):</b>
        </div>

        <div
          class="col-auto"
          v-if="
            (rJuri_index == null && isAll === 'all' + jurisdiction) ||
            (rJuri_index !== null &&
              research_courtnames[rJuri_index] ==
                'all' + research_jurisdictions[rJuri_index])
          "
        >
          <span
            @click="resetIsAll(rJuri_index)"
            class="court_badge cursor-pointer"
          >
            {{ `All Courts and Institutions in ` }}
            {{
              rJuri_index == null
                ? jurisdiction
                : research_jurisdictions[rJuri_index]
            }}
            <span class="material-icons-outlined">close</span>
          </span>
        </div>

        <div
          v-else-if="rJuri_index == null"
          class="col-auto"
          v-for="(chosen, choose_court_list_idx) in choose_court_list"
          :key="choose_court_list_idx"
        >
          <span @click="removeCourt(chosen)" class="court_badge cursor-pointer">
            {{ chosen.name }}
            <span class="material-icons-outlined">close</span>
          </span>
        </div>

        <div
          v-else-if="(research_courtnames[rJuri_index].length ?? -1) > 0"
          class="col-auto"
          v-for="(chosen, index) in research_courtnames[rJuri_index]"
          :key="index"
        >
          <span
            class="court_badge cursor-pointer"
            @click="removeCourt(chosen, rJuri_index)"
          >
            {{ chosen.name }}
            <span class="material-icons-outlined">close</span>
          </span>
        </div>
      </div>

      <b-form-input
        class="mt-3"
        v-model.trim="choose_court"
        @input="search_choose_court"
        placeholder="Search Court"
        autocorrect="off"
        autocomplete="off"
      ></b-form-input>
      <!-- end of Chosen Court Section -->

      <!-- start of Court List Section -->
      <h5 class="mt-4">
        <b>{{ "Courts and Institutions in " }}
        {{
          rJuri_index == null
            ? jurisdiction
            : research_jurisdictions[rJuri_index]
        }}</b>
      </h5>

      <div class="row juris-modal">
        <div class="col">
          <div v-if="sort_alphabetically == 'all'" class="row">
            <div class="col-12 d-flex align-items-center mb-3">
              <b-form-checkbox
                v-if="rJuri_index == null"
                id="isAll"
                v-model="isAll"
                name="isAll"
                :value="'all' + jurisdiction"
              >
                Choose All Courts & Institutions
                <span style="color: #0d6efd">
                  (Choose this if you don't know which court the case is
                  filed in/ associated with)
                </span>
              </b-form-checkbox>
              <b-form-checkbox
                v-else
                id="RisAll"
                v-model="research_courtnames[rJuri_index]"
                name="RisAll"
                :value="'all' + research_jurisdictions[rJuri_index]"
                @change="handleRAll"
              >
                All Courts & Institutions
                <span style="color: #0d6efd">
                  (Choose this if you don't know which court the case is
                  filed in/ associated with)
                </span>
              </b-form-checkbox>
            </div>
            <div class="col">
              <div
                class="row"
                v-for="(courts, idx) in courts_in_country.courts"
                :key="idx"
              >
                <div class="col mb-2">
                  <h6 class="font-weight-bold">
                    {{ courts.hierarchy | capitalize }}
                  </h6>

                  <b-form-checkbox
                    @change="chooseCourt(item, rJuri_index)"
                    v-model="selectedCourts"
                    :value="item.name"
                    :disabled="
                      isAll ===
                        (rJuri_index == null
                          ? 'all' + jurisdiction
                          : 'all' + research_jurisdictions[rJuri_index]) ||
                      (rJuri_index == null &&
                        selectedCourts.length > 1 &&
                        choose_court_list.length > 1 &&
                        !choose_court_list
                          .map((crt) => crt.name)
                          .includes(item.name)) ||
                      research_courtnames[rJuri_index] ==
                        'all' + research_jurisdictions[rJuri_index] ||
                      (rJuri_index !== null &&
                        !research_courtnames[rJuri_index]?.includes(item) &&
                        (research_courtnames[rJuri_index]?.length ?? -1) >
                          1)
                    "
                    class="font-16 block cursor-pointer"
                    v-for="(item, courts_idx) in courts.courts"
                    :key="courts_idx"
                  >
                    {{ item.name }}
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </div>

          <div v-else-if="sort_alphabetically == 'search'" class="row">
            <div class="col">
              <div
                class="row"
                v-for="(courts, idx) in courts_in_country.courts"
                :key="idx"
              >
                <div class="col">
                  <b-form-checkbox
                    @change="chooseCourt(item)"
                    v-show="
                      item.name
                        .toLowerCase()
                        .includes(choose_court.toLowerCase())
                    "
                    v-model="selectedCourts"
                    :value="item.name"
                    :disabled="
                      isAll ===
                        (rJuri_index == null
                          ? 'all' + jurisdiction
                          : 'all' + research_jurisdictions[rJuri_index]) ||
                      (rJuri_index == null &&
                        selectedCourts.length > 0 &&
                        choose_court_list.length > 0 &&
                        choose_court_list[0].name !== item.name) ||
                      research_courtnames[rJuri_index] ==
                        'all' + research_jurisdictions[rJuri_index] ||
                      (rJuri_index !== null &&
                        !research_courtnames[rJuri_index]?.includes(item) &&
                        (research_courtnames[rJuri_index]?.length ?? -1) >
                          1)
                    "
                    class="font-16 block cursor-pointer"
                    v-for="(item, courts_idx) in courts.courts"
                    :key="courts_idx"
                  >
                    {{ item.name }}
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </div>

          <div v-else class="row">
            <div class="col">
              <div
                class="row"
                v-for="(courts, idx) in courts_in_country.courts"
                :key="idx"
              >
                <div class="col">
                  <b-form-checkbox
                    @change="chooseCourt(item)"
                    v-show="
                      item.name[0].toLowerCase() ==
                      choosen_alpha.toLowerCase()
                    "
                    v-model="selectedCourts"
                    :value="item.name"
                    :disabled="
                      isAll ===
                        (rJuri_index == null
                          ? 'all' + jurisdiction
                          : 'all' + research_jurisdictions[rJuri_index]) ||
                      (rJuri_index == null &&
                        selectedCourts.length > 0 &&
                        choose_court_list.length > 0 &&
                        choose_court_list[0].name !== item.name) ||
                      research_courtnames[rJuri_index] ==
                        'all' + research_jurisdictions[rJuri_index] ||
                      (rJuri_index !== null &&
                        !research_courtnames[rJuri_index]?.includes(item) &&
                        (research_courtnames[rJuri_index]?.length ?? -1) >
                          1)
                    "
                    class="font-16 block cursor-pointer"
                    v-for="(item, courts_idx) in courts.courts"
                    :key="courts_idx"
                  >
                    {{ item.name }}
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-1">
          <span @click="openAll" class="cursor-pointer text-muted font-14">
            {{ "All" }}
          </span>
          <span
            v-for="(alpha, alphabets_idx) in courts_in_country.Alphabet"
            :key="alphabets_idx"
            @click="choose_alphabet(alpha)"
            class="text-muted cursor-pointer font-14 display-block"
          >
            {{ alpha | capitalize }}
          </span>
        </div>
      </div>
      <!-- end of Court List Section -->

      <!-- start of Footer Section -->
      <button
        @click="finalised_courts('add')"
        class="btn btn-primary float-right px-4 m-2"
      >
        {{ "Done" }}
      </button>

      <button
        @click="finalised_courts('hide')"
        class="btn btn-primary-outlined float-right px-4 m-2"
      >
        {{ "Cancel" }}
      </button>
      <!-- end of Footer Section -->
    </b-modal>
    <!-- end of Court & Institution modal -->

    <div class="page-wrapper-xl">
      <div class="nexcard p-4">
        <!-- start of Form header -->
        <h3 class="modal-card__title text-center mb-4">
          {{ "Final Phase" }}
        </h3>
        <div class="step__row mb-4" style="max-width: 300px">
          <div class="step__col-auto" id="generalDetailsIcon">
            <img src="@/assets/input-step-icons/icon-step-complete.svg" />
            <span class="step__name">{{ "Phase 1" }}</span>
          </div>
          <hr />
          <div class="step__col-auto" id="letterDetailsIcon">
            <img src="@/assets/input-step-icons/icon-step2-inactive.svg" />
            <span class="step__name">{{ "Phase 2" }}</span>
          </div>
        </div>
        <!-- end of Form header -->

        <div class="general_container">
          <!-- start of trial doc -->
          <h5 class="mb-0">
              <b>{{ "Upload Trial Document" }}</b>
          </h5>
          <div class="row mt-4 mb-3">
              <div class="col">
                <input
                  class="form-control"
                  type="file"
                  id="formFile"
                  ref="file_Judgement"
                  accept=".pdf"
                  @change="handleUploadFileChange"
                />
                <small class="font-12 d-flex align-items-center">
                  <b-icon icon="info-circle" />
                  &nbsp;Please upload PDF file only
                </small>
              </div>

              <div class="col">
                <input
                  class="form-control"
                  type="text"
                  id="fileName"
                  placeholder="File Name"
                  v-model="selectedFileName"
                />
                <small class="font-12 d-flex align-items-center">
                  <b-icon icon="info-circle" />
                  &nbsp;Please rename your file
                </small>
              </div>
          </div>
          <!-- end fo trial doc -->
          <!-- start of Client Case -->
          <b>
            {{ "Client's Case" }}
            <span
              id="tooltip-target-3"
              class="text-danger cursor-pointer"
            >*</span>
          </b>
          <b-tooltip
            target="tooltip-target-3"
            triggers="hover"
            placement="top"
            variant="secondary"
            id="tooltip3"
            title="Required"
          ></b-tooltip>

          <div class="mt-1 mb-3">
            <b-form-textarea
              v-model.trim="client_case"
              id="textarea"
              :placeholder="`One line explanation of the case being handled. Please state the basic theme of your client's case in not more than 1 sentence.\n\nFor example: \nAlleging three counts of defamation against ex-wife including domestic violence and false allegations.`"
              autocorrect="off"
              autocomplete="off"
              rows="5"
              max-rows="5"
              v-validate="'required'"
              type="text"
              name="Client's Case"
            ></b-form-textarea>
            <small class="text-muted float-right count_length">
              {{ getWordCount(client_case) }} / 50
            </small>

            <!-- error displays -->
            <span
              class="text-danger text-sm"
              v-show="getWordCount(client_case) > 50"
            >
              {{ "Should not be more than 50 words" }}
            </span>
            <span class="text-danger text-sm">
              {{ errors.first("Client's Case") }}
            </span>
          </div>
          <!-- end of Client Case -->

          <!-- start of Client Type -->
          <b>
            Client Type
            <span
              id="tooltip-target-4"
              class="text-danger font-weight-bold cursor-pointer"
            >*</span>
          </b>
          <b-tooltip
            target="tooltip-target-4"
            triggers="hover"
            placement="top"
            variant="secondary"
            id="tooltip4"
            title="Required"
          ></b-tooltip>

          <div class="mt-1 mb-3">
            <b-form-select
              class="form-select w-100 h-100 rounded"
              :class="{ 'text-muted': client_type === '' }"
              id="inputGroupSelect04"
              aria-label="Example select with button addon"
              v-model="client_type"
              v-validate="'required'"
              name="Client Type"
            >
              <b-form-select-option value="" disabled selected>
                {{ "Please Select a Client Type" }}
              </b-form-select-option>
              <b-form-select-option
                class="text-softblack"
                :value="item"
                :key="index"
                v-for="(item, index) in ['petitioner', 'respondent']"
              >
                {{ item | capitalize }}
              </b-form-select-option>
            </b-form-select>
            <span class="text-danger text-sm">
              {{ errors.first("Client Type") }}
            </span>
          </div>
          <!-- end of Client Type -->

          <!-- start of Jurisdiction & Court and Institutions -->
          <div class="mt-2 d-flex align-items-center">
            <b>Main Jurisdiction and Court</b>
            <span
              id="tooltip-target-1"
              class="span_badge material-icons-outlined cursor-pointer"
            >
              help_outline
            </span>
            <b-tooltip
              target="tooltip-target-1"
              triggers="hover"
              placement="righttop"
              variant="secondary"
              custom-class="LESInputTooltip"
            >
              The jurisdiction and court in which the case is filed or
              associated with
            </b-tooltip>
            <span
              id="tooltip-target-5"
              class="text-danger font-weight-bold cursor-pointer"
            >*</span>
            <b-tooltip
              target="tooltip-target-5"
              triggers="hover"
              placement="top"
              variant="secondary"
              id="tooltip5"
              title="Required"
            ></b-tooltip>
          </div>

          <div class="row mt-2 mb-3">
            <!-- Jurisdiction -->
            <div class="col">
              <b-form-select
                class="form-select w-100 rounded"
                :class="{ 'text-muted': jurisdiction === '' }"
                id="inputGroupSelect04"
                aria-label="Example select with button addon"
                v-model="jurisdiction"
                @change="getCourts(jurisdiction)"
                name="Juri"
                v-validate="'required'"
              >
                <b-form-select-option value="" disabled selected>
                  {{ "Please Select a Country" }}
                </b-form-select-option>
                <b-form-select-option
                  class="text-softblack"
                  v-for="(item, index) in jurisdictions"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </b-form-select-option>
              </b-form-select>
            </div>

            <!-- Court & Institution -->
            <div class="col-7" @click="openJurisdiction()">
              <div :class="[
                'd-flex justify-content-center h-100 card px-1',
                { 'cursor-pointer': this.jurisdiction.length > 0 },
              ]">
                <p
                  class="text-muted my-0 ms-1"
                  v-if="finalised_choose_courts.length == 0"
                >
                  {{
                    this.jurisdiction.length == 0
                      ? "Please Select a Jurisdiction"
                      : "Click to Select Court"
                  }}
                </p>

                <template v-else>
                  <span
                    v-for="(court, index) in finalised_choose_courts"
                    :key="index"
                    class="d-inline court_badge coursor-pointer my-1 mx-1"
                    @click.stop="removeCourt(court, null)"
                  >
                    {{ court.name }}
                    <span class="material-icons-outlined">close</span>
                  </span>
                </template>
              </div>
            </div>

            <div
              class="col-12"
              v-if="finalised_choose_courts.length === 0 && errors.has('Juri')"
            >
              <span class="text-danger text-sm">
                {{ "The Main Jurisdiction and Court field is required" }}
              </span>
            </div>
          </div>
          <!-- end of Jurisdiction & Court and Institutions -->

          <!-- start of Lawyer Name -->
          <!-- <div class="d-flex align-items-center" v-if="lawyer_name_format?.[jurisdiction]">
            <b>Adverse Lawyer Name</b>
            <span
              id="tooltip-target-7"
              class="span_badge material-icons-outlined cursor-pointer"
            >
              help_outline
            </span>
            <b-tooltip
              target="tooltip-target-7"
              trigger="hover"
              placement="righttop"
              variant="secondary"
              custom-class="LESInputTooltip"
            >
              To generate Adverse Lawyer analysis (Optional)
            </b-tooltip>
          </div>
          <div class="row mt-2" v-if="lawyer_name_format?.[jurisdiction]">
            <div class="col" v-if="lawyer_name_format[jurisdiction][0] == 1">
              <b-form-input
                class="mb-3"
                placeholder="First Name"
                v-model.trim="lawyer_firstname"
              ></b-form-input>
            </div>
            <div class="col" v-if="lawyer_name_format[jurisdiction][1] == 1">
              <b-form-input
                class="mb-3"
                placeholder="Middle Name Initial"
                v-model.trim="lawyer_midname"
              ></b-form-input>
            </div>
            <div class="col" v-if="lawyer_name_format[jurisdiction][2] == 1">
              <b-form-input
                class="mb-3"
                placeholder="Surname"
                v-model.trim="lawyer_surname"
              ></b-form-input>
            </div>
          </div> -->
          <!-- end of Lawyer Name -->

          <!-- start of Judge Name & Title -->
          <!-- <div class="d-flex align-items-center" v-if="judge_name_format?.[jurisdiction]">
            <b>Judge Name</b>
            <span
              id="tooltip-target-8"
              class="span_badge material-icons-outlined cursor-pointer"
            >
              help_outline
            </span>
            <b-tooltip
              target="tooltip-target-8"
              trigger="hover"
              placement="righttop"
              variant="secondary"
              custom-class="LESInputTooltip"
            >
              To generate Judge analysis (Optional)
            </b-tooltip>
          </div>
          <div class="row mt-2" v-if="judge_name_format?.[jurisdiction]">
            <div class="col" v-if="jurisdiction == 'Canada'">
              <select
                class="mb-3 form-select"
                :class="{ 'text-muted': judge_title == null }"
                placeholder="Title"
                v-model="judge_title"
              >
                <option class="mt-2" value="null" disabled selected>
                  Judge Title
                </option>
                <option
                  class="mt-2 text-softblack"
                  v-for="(title, index) in judge_titles"
                  :key="index"
                  :value="title"
                >
                  {{ title }}
                </option>
              </select>
            </div>
            <div class="col" v-if="judge_name_format[jurisdiction][0] == 1">
              <b-form-input
                class="mb-3"
                placeholder="First Name"
                v-model.trim="judge_firstname"
              ></b-form-input>
            </div>
            <div class="col" v-if="judge_name_format[jurisdiction][1] == 1">
              <b-form-input
                class="mb-3"
                placeholder="Middle Name Initial"
                v-model.trim="judge_midname"
              ></b-form-input>
            </div>
            <div class="col" v-if="judge_name_format[jurisdiction][2] == 1">
              <b-form-input
                class="mb-3"
                placeholder="Surname"
                v-model.trim="judge_surname"
              ></b-form-input>
            </div>
          </div> -->
          <!-- end of Judge Name & Title -->

          <!-- start of Research Jurisdictions -->
          <template v-if="jurisdiction && jurisdiction !== 'United States of America'">
            <div class="d-flex align-items-center">
              <b>Number of Research Jurisdiction(s) and Court(s):</b>
              <span
                id="tooltip-target-2"
                class="span_badge material-icons-outlined cursor-pointer"
              >
                help_outline
              </span>
              <b-tooltip
                target="tooltip-target-2"
                trigger="hover"
                placement="righttop"
                variant="secondary"
                custom-class="LESInputTooltip"
              >
                Choose jurisdictions to get resources for the case
              </b-tooltip>
            </div>
            <div
              class="card p-2 mt-1"
              v-for="(juri, index) in research_jurisdictions"
              :key="index"
            >
              <div class="row">
                <div class="col-4 d-flex align-items-start">
                  <p style="margin-top: 7px" class="mb-0 mx-2">
                    {{ index + 1 }}.
                  </p>
                  <b-form-select
                    id="inputGroupSelect04"
                    :class="[
                      'form-select w-100 mb-0 rounded',
                      { 'text-muted': !research_jurisdictions[index] }
                    ]"
                    name="Research Jurisdictions"
                    v-model="research_jurisdictions[index]"
                    @change="openJurisdiction(index)"
                  >
                    <b-form-select-option value="" disabled selected>
                      {{ "Please Select a Country" }}
                    </b-form-select-option>
                    <b-form-select-option
                      class="text-softblack"
                      v-for="(item, idx) in rJuriOptions[index]"
                      :key="idx"
                      :value="item"
                    >
                      {{ item }}
                    </b-form-select-option>
                  </b-form-select>
                </div>

                <div class="col" @click="openJurisdiction(index)">
                  <div
                    :class="[
                      'd-flex justify-content-center h-100 card px-1',
                      { cursor_pointer: juri?.length > 0 },
                    ]"
                  >
                    <p
                      v-if="research_finalcourt[index].length == 0"
                      class="text-muted my-0 ms-1"
                    >
                      {{
                        !juri
                          ? "Please Select a Jurisdiction"
                          : "Click to Select Court"
                      }}
                    </p>

                    <span
                      v-else-if="
                        research_finalcourt[index] ==
                        'all' + research_jurisdictions[index]
                      "
                      class="court_badge cursor-pointer my-1"
                      @click.stop="
                        removeCourt(
                          'all' + research_jurisdictions[index],
                          index
                        )
                      "
                    >
                      {{
                        "All Courts and Institutions in " +
                        research_jurisdictions[index]
                      }}
                      <span class="material-icons-outlined">close</span>
                    </span>

                    <span
                      v-else
                      v-for="(court, idx) in research_finalcourt[index]"
                      :key="idx"
                      class="court_badge cursor-pointer my-1"
                      @click.stop="removeCourt(court, index)"
                    >
                      {{ court.name }}
                      <span class="material-icons-outlined">close</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <!-- end of Research Jurisdictions -->

          <!-- start of Background/Facts of The Case >> Pre-event Findings -->
          <div class="mt-3">
            <b>
              {{ "Background/Facts of The Case" }}
              <span
                id="tooltip-target-6"
                class="text-danger cursor-pointer"
              >*</span>
            </b>
            <b-tooltip
              target="tooltip-target-6"
              triggers="hover"
              placement="top"
              variant="secondary"
              id="tooltip6"
              title="Required"
            ></b-tooltip>
          </div>
          <div class="card p-3 mt-2">
            <b>Upload File</b>
            <input
              class="form-control mt-2"
              type="file"
              id="formFile"
              ref="PreEventFile"
              accept=".pdf,.docx"
              @change="handleFileChange"
            />
            <small
              class="font-12 mx-1 text-center d-flex align-items-center"
            >
              <b-icon class="mx-1" icon="info-circle" />
              Please upload PDF or Document file only
            </small>
            
            <div class="w-100 d-flex justify-content-center">
              <hr id="longLine" />
              <b class="mt-1">OR</b>
              <hr id="longLine" />
            </div>

            <b>Text Input</b>
            <div>
              <b-form-textarea
                id="textarea"
                class="mt-1"
                :placeholder="`All the relevant information about the case including but not limited to: \nEvent Timeline, Pre-incident Phase, Post-Incident Phase, Incident Phase, Investigation and Legal Proceedings Phase, evidences, witnesses, damages, relief sought and factual background of the case. \n\nFor example: \nFin, as plaintiff, filed a complaint of defamation against defendant Kate claiming $5 million in damages; Kate filed counterclaims against Fin claiming $10 million in damages. Fin and Kate first met in 2009, and got married in February 2015. In May 2016, at an early stage in their divorce proceedings, Kate claimed that Fin had abused her physically, which he denied. Further she posted a news about the abuse which defamed Fin. Fin wishes to open a case of defamation and domestic violence against Kate.`"
                autocorrect="off"
                autocomplete="off"
                rows="12"
                max-rows="12"
                type="text"
                name="Background/Facts of The Case"
                v-model.trim="pre_event_finding"
                v-validate="'required'"
              ></b-form-textarea>
              <small class="text-muted float-right count_length">
                {{ getWordCount(pre_event_finding) }} / 10000
              </small>

              <span
                class="text-danger text-sm"
                v-show="getWordCount(pre_event_finding) > 10000"
              >
                {{ "Should not be more than 10000 words" }}
              </span>
            </div>
          </div>
          <span
            class="text-danger text-sm"
            v-if="!pre_event_file && errors.has('Background/Facts of The Case')"
          >
            {{ "The Background/Facts of The Case field is required" }}
          </span>
          <!-- end of Background/Facts of The Case >> Pre-event Findings -->

          <div style="height: 20px">
            <button
              :disabled="isSubmitting"
              @click="sendValue"
              class="btn-primary btn float-right px-5 my-4"
            >
              {{ "Next" }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import LegalExpertSystem from "@/store/LES.js";
import { courts } from "../CaseSearch/courtnames";
import { getWordCount, groupValidateInput, validateInput } from "../../store/utils";

export default {
  data() {
    return {
      selectedFile: null,
      selectedFileName: "",
      isSubmitting: false,
      showEvidenceInputs: true,
      
      client_case: "",
      client_type: "",

      jurisdiction: "",
      jurisdictions: [
        "United States of America",
        "United Kingdom",
        "Australia",
        "Canada",
        "New Zealand",
        "Singapore",
        "Malaysia",
      ],

      sort_alphabetically: "all",
      exceededTone: false,
      chosen_tone_value: "",
      isAll: "",
      choose_court: "",

      choosen_alpha: "",
      courts: courts,
      courts_in_country: [],

      selectedCourts: [],
      choose_court_list: [],
      finalised_choose_courts: [],

      rJuri_number: 0,
      rJuri_index: null,
      research_jurisdictions: [],
      research_courtnames: [],
      research_finalcourt: [],

      lawyer_name_format: {
        "United States of America": [1, 1, 1],
        Australia: [1, 1, 1],
        "New Zealand": [1, 1, 1],

        "United Kingdom": [1, 0, 1],
        Singapore: [1, 0, 1],

        "": null,
      },
      lawyer_firstname: null,
      lawyer_midname: null,
      lawyer_surname: null,

      judge_name_format: {
        "United States of America": [1, 1, 1],

        "United Kingdom": [1, 0, 1],
        "New Zealand": [1, 0, 1],
        Canada: [1, 0, 1],

        "": null,
      },
      judge_firstname: null,
      judge_midname: null,
      judge_surname: null,
      judge_title: null,
      judge_titles: [
        "C.J.C",
        "C.C.J",
        "P.J.C",
        "S.C.C",
        "P.J",
        "J.P",
        "C.J",
        "J.",
      ],

      pre_event_finding: "",
      pre_event_file: null,
    };
  },

  computed: {
    rJuriOptions() {
      const array = [];
      for (var i = 0; i < 5; i++) {
        array.push(
          this.jurisdictions.filter(
            (juri) =>
              juri !== "United States of America" &&
              juri !== this.jurisdiction &&
              (!this.research_jurisdictions.includes(juri) ||
                this.research_jurisdictions[i] == juri)
          )
        );
      }
      return array;
    },
  },

  methods: {
    getWordCount(text) { return getWordCount(text); },

    handleFileChange(event) {
      var result = validateInput(event.target.files[0], "file", ["pdf", "docx"]);
      if (!result[0] && result[1] !== "empty") {
        this.toastError(result[1]);
        this.pre_event_file = null;
        this.$refs.PreEventFile.value = null;
      } else {
        this.pre_event_file = result[1] === "empty"
          ? null : result[1];
      }
    },
    handleUploadFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedFile = file;
        this.selectedFileName = file.name;
      } else {
        this.selectedFile = null;
        this.selectedFileName = "";
      }
    },
    choose_alphabet(alp) {
      this.sort_alphabetically = "alpha";
      this.choose_court = "";
      this.choosen_alpha = alp;
    },
    search_choose_court() {
      this.sort_alphabetically =
        this.choose_court.length == 0 ? "all" : "search";
      this.choosen_alpha = "";
    },
    openAll() {
      this.sort_alphabetically = "all";
      this.choose_court = "";
      this.choosen_alpha = "";
    },

    resetIsAll(index = null) {
      if (index !== null) {
        this.$set(this.research_courtnames, index, []);
        this.selectedCourts = [];
      } else {
        this.isAll = "";
        this.exceededTone = false;
        this.finalised_choose_courts = [];
      }
    },
    getCourts(crt) {
      this.research_jurisdictions = [];
      this.research_courtnames = [];
      this.research_finalcourt = [];

      this.rJuri_number = 1;
      this.updateRJuri();

      this.sort_alphabetically = "all";
      this.courts_in_country = [];
      this.choose_court_list = [];
      this.finalised_choose_courts = [];

      this.courts_in_country = this.courts[crt];
      this.$bvModal.show("court-modal");

      this.resetNames();
    },

    handleRAll() {
      const allcourts = this.research_courtnames[this.rJuri_index];
      if (
        allcourts[allcourts.length - 1] ==
        "all" + this.research_jurisdictions[this.rJuri_index]
      ) {
        this.research_courtnames[this.rJuri_index] = [
          allcourts[allcourts.length - 1],
        ];
        this.selectedCourts = allcourts;
      } else {
        this.research_courtnames[this.rJuri_index] = [];
        this.selectedCourts = [];
      }
    },

    openJurisdiction(index = null) {
      if (
        (index == null && this.jurisdiction.length == 0) ||
        this.research_jurisdictions[index]?.length == 0
      ) {
        return;
      }

      this.rJuri_index = index;
      this.courts_in_country =
        this.courts[this.research_jurisdictions[index] ?? this.jurisdiction];

      this.selectedCourts =
        [
          ...(index == null
            ? this.finalised_choose_courts
            : this.research_courtnames[index]
          )?.map((crt) => crt.name),
        ] ?? [];

      this.$bvModal.show("court-modal");
    },

    removeCourt(court, index = null) {
      if (index == null && this.isAll === "all" + this.jurisdiction) {
        this.resetIsAll();
      } else if (index == null) {
        this.choose_court_list = this.choose_court_list.filter(
          (crt) => crt !== court
        );
        this.selectedCourts = this.choose_court_list.map((crt) => crt.name);
        this.finalised_choose_courts = this.choose_court_list;
      } else {
        this.selectedCourts = [];
        this.research_courtnames[index] = this.research_courtnames[
          index
        ].filter((crt) => crt !== court);
        this.research_finalcourt[index] = [...this.research_courtnames[index]];
      }
    },

    finalised_courts(flag) {
      if (this.rJuri_index !== null) {
        if (flag == "add") {
          this.research_finalcourt = [...this.research_courtnames];

          if (
            this.rJuri_number < 5 &&
            this.rJuri_index == this.rJuri_number - 1
          ) {
            this.rJuri_number++;
            this.updateRJuri();
          }
        }

        this.rJuri_index = null;
        this.$bvModal.hide("court-modal");
        return;
      }

      this.openAll();
      if ((flag = "add")) {
        if (this.isAll == "all" + this.jurisdiction) {
          const allCourt = {
            id: this.isAll,
            name: "All Courts and Institutions in " + this.jurisdiction,
          };
          this.choose_court_list = [allCourt];
        }
        // console.log(this.choose_court_list);
        this.finalised_choose_courts = this.choose_court_list;
      }
      this.rJuri_index = null;
      this.$bvModal.hide("court-modal");
    },

    chooseCourt(crt, index = null) {
      if (index !== null) {
        if (this.research_courtnames[index]?.includes(crt) ?? false) {
          this.research_courtnames[index] = this.research_courtnames[
            index
          ].filter((item) => item !== crt);
        } else if (this.research_courtnames[index].length < 2) {
          if (Array.isArray(this.research_courtnames[index])) {
            this.research_courtnames[index].push(crt);
          } else {
            this.research_courtnames[index] = [crt];
          }
        }
        this.selectedCourts = this.research_courtnames[index];
        return;
      }

      const isChecked = this.selectedCourts?.includes(crt.name);
      if (isChecked) {
        // If checked, add to the choose_court_list array
        if (this.choose_court_list.length < 2) {
          if (this.isAll === "all" + this.jurisdiction) {
            this.exceededTone = true;
            this.choose_court_list = [];
            this.selectedCourts = [];
          } else {
            this.choose_court_list.push(crt);
          }
        } else {
          this.exceededTone = true;
        }
      } else {
        // If unchecked, remove from the choose_court_list array
        const index = this.choose_court_list.findIndex(
          (court) => court.name === crt.name
        );
        if (index !== -1) {
          this.choose_court_list.splice(index, 1);
        }
        this.exceededTone = false;
      }
      // console.log(this.selectedCourts);
      // console.log(this.choose_court_list);
    },

    updateRJuri() {
      const rJuriLength = this.research_jurisdictions.length;
      if (rJuriLength < this.rJuri_number) {
        for (let i = 0; i < this.rJuri_number - rJuriLength; i++) {
          this.research_jurisdictions.push("");
          this.research_courtnames.push([]);
          this.research_finalcourt.push([]);
        }
      } else if (rJuriLength > this.rJuri_number) {
        this.research_jurisdictions.splice(this.rJuri_number);
        this.research_courtnames.splice(this.rJuri_number);
        this.research_finalcourt.splice(this.rJuri_number);
      }
    },

    resetNames() {
      this.lawyer_firstname = null;
      this.lawyer_midname = null;
      this.lawyer_surname = null;
      this.judge_firstname = null;
      this.judge_midname = null;
      this.judge_surname = null;
      this.judge_title = null;
    },

    clearArrData(flag, index) {
      this.$set(
        this[this.dataKey[flag].arr],
        index,
        {
          name: "",
          statement: "",
        }
      );
    },
    removeArrData(flag, index) {
      const KEY = this.dataKey[flag];
      this[KEY.arr].splice(index, 1);
      this[KEY.num] = this[KEY.arr].length;
    },
    updateArr(flag) {
      const KEY = this.dataKey[flag];
      const ArrLength = this[KEY.arr].length;

      if (this[KEY.num] < 1 || this[KEY.num] > 10) {
        this[KEY.num] = ArrLength;
        return;
      }

      // increase
      if (this[KEY.num] > ArrLength) {
        for (let i = 0; i < this[KEY.num] - ArrLength; i++) {
          setTimeout(() => {
            this[KEY.arr].push({
              name: "",
              statement: "",
            });
          }, 30 * i);
        }
      }
      // decrease
      else if (this[KEY.num] < ArrLength) {
        for (let i = ArrLength; i > this[KEY.num]; i--) {
          this[KEY.arr].splice(this[KEY.arr].length - 1);
        }
      }
    },
    parseCollectionValue(original) {
      return JSON.stringify(
        original.reduce((acc, obj) => {
          acc[obj.name] = obj.statement;
          return acc;
        }, {})
      );
    },
    validateFileType(file) {
      const validTypes = ["application/pdf", "image/png", "image/jpeg"];
      return validTypes.includes(file.type);
    },
    toastError(flag) {
      this.$toast.error({
        "empty": "Please fill in all required fields",
        "max": "Maximum word limit exceeded",
        "pre_event": "Please upload file OR input text for Background/Facts of The Case",
        "courts": "Please select court(s) for each chosen jurisdiction",
        "file": "Please upload a .pdf or .docx file"
      }[flag]);
    },

    async sendValue() {
        if (this.isSubmitting) return;
        this.isSubmitting = true;

        try {
          await this.$validator.validateAll();

          // Construct jurisdiction object
          let jurisdiction = {};
          jurisdiction[this.jurisdiction] = this.finalised_choose_courts;
          this.research_jurisdictions.forEach((juri, id) => {
            jurisdiction[juri] = this.research_courtnames[id];
          });

          // Validate inputs
          const Results = groupValidateInput([
            [this.client_case, "string", ["max50"]],
            [this.client_type, "string"],
            [jurisdiction, "courts", ["LES"]],
            [this.pre_event_finding, "string", ["max10000"]],
            [[this.lawyer_firstname, this.lawyer_midname, this.lawyer_surname].join(" "), "string"],
            [[this.judge_firstname, this.judge_midname, this.judge_surname].join(" "), "string"]
          ]);

          for (let i = 0; i < Results.length; i++) {
            if (
              (i < 3 && !Results[i][0]) ||
              (i > 3 && !Results[i][0] && Results[i][1] !== "empty")
            ) {
              this.toastError(Results[i][1]);
              this.isSubmitting = false;
              return;
            }
          }

          // Extra check for pre-event findings
          let pre_event = Results[3];
          if (this.pre_event_finding && this.pre_event_file) {
            this.toastError("pre_event");
            this.isSubmitting = false;
            return;
          } else if (
            (!pre_event[0] && pre_event[1] === "max") ||
            (!pre_event[0] && pre_event[1] === "empty" && !this.pre_event_file)
          ) {
            this.toastError(pre_event[1]);
            this.isSubmitting = false;
            return;
          } else {
            pre_event[1] = pre_event[0] ? pre_event[1] : this.pre_event_file;
          }

          const lawyer_name = Results[4];
          const judge_name = Results[5];

          // Create bodyFormData and append data
          let bodyFormData = new FormData();

          bodyFormData.append("client_case", Results[0][1]);
          bodyFormData.append("client_type", Results[1][1]);
          bodyFormData.append("jurisdiction",Results[2][1]);
          bodyFormData.append("language", "en");
          bodyFormData.append("location_id", 1);
          // bodyFormData.append("client_id",[]);
          if (lawyer_name[0]) {
            bodyFormData.append("lawyer", lawyer_name[1]);
          }
          if (judge_name[0]) {
            bodyFormData.append("judge", judge_name[1]);
          }
          if (this.judge_title) {
            bodyFormData.append("judge_title", this.judge_title);
          }

          // Handle pre-event and trial_doc
          if (pre_event[0]) {
            bodyFormData.append("pre_event", pre_event[1]);
          } else if (pre_event[1] instanceof File) {
            bodyFormData.append("pre_event_file", pre_event[1]);
            bodyFormData.append("file_name", pre_event[1].name.replace(/.pdf|.docx/, ""));
          }
          if (this.selectedFile && this.selectedFile.size > 0) {
            if (!this.validateFileType(this.selectedFile)) {
              this.toastError("file");
              return;
            }
            bodyFormData.append("trial_doc", this.selectedFile);
          }

          // Call the API
          const response = await LegalExpertSystem.LesFinalSubmissionFormData(bodyFormData);
          this.$router.push({
            name: "LegalExpertSystemPreTrialResult",
            params: {
              id: response.data.data?.id,
              category: "postTrial"
            }
          });

        } catch (error) {
          console.error("Validation or submission error:", error);
          this.toastError("An unexpected error occurred.");
        } finally {
          this.isSubmitting = false;
        }
      }

  },

  watch: {
    isAll() {
      this.selectedCourts = [];
      this.choose_court_list = [];
    },
  },
};
</script>

<style>
.nexcard {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
  /* padding: 1.5rem; */
}

hr {
  width: 60px;
  margin: 1rem 0.5rem 1rem 0.5rem;
}
.step__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 900px;
  margin: 0 auto;
}
.step__col {
  min-width: 20px;
  text-align: start;
  user-select: none;
}

.step__name {
  white-space: pre;
  margin-left: 0.5rem;
}

.general_container {
  margin: 3rem 2rem;
}

header.card-header {
  padding: 0px !important;
}

.accordion-header {
  background-color: transparent;
  width: 100%;
  height: 100%;
  border-color: transparent;
  color: black;
  text-align: left;
}
.accordion-header:hover {
  background-color: transparent;
}

.card-body {
  padding-bottom: 0px;
}

.card-header[aria-expanded="true"] {
  background-color: black;
}

.accordion-header[aria-expanded="true"] .arrow-icon {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.accordion-header[aria-expanded="false"] .arrow-icon {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.arrow-icon {
  display: none;
  display: block;
  -webkit-transition: transform 0.5s ease;
  -o-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
  color: black;
}

.name {
  display: flex;
  align-items: center;
  padding: 0.4rem;
  flex-grow: 1;
  color: black !important;
}
/* 
.accordion-header:hover .material-symbols-outlined {
  color: white;
} */

.redbtn {
  padding-top: 2px;
}

.redbtn:hover {
  color: red;
  background-color: transparent;
}

.bi {
  font-size: 22px;
}

.court_badge {
  max-width: 98%;
  border: none;
  background: #d2d2d2;
  color: black;
  border-radius: 20px;
  font-size: 14px;
  padding: 0.2rem 0.5rem;
  width: max-content;
}

.court_badge .material-icons-outlined {
  font-size: 14px;
  translate: 0.2rem 0.1rem;
}

.juris-modal {
  max-height: 425px;
  overflow-y: auto;
  overflow-x: hidden;
}

.bi-info-circle {
  width: 1.5%;
}

.span_badge {
  border: none;
  color: black;
  border-radius: 20px;
  font-size: 17px;
  padding: 0.1rem 0.2rem;
}

.form-control::file-selector-button {
  background-color: #9f9fa09a;
  border: 1px solid #9f9fa09a;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
}
</style>

<style>
.LESInputTooltip .tooltip-inner {
  max-width: 500px;
}
</style>
